body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login-form {
  justify-content: center;
  min-height: 90vh;
}
.button-block {
  width: 100%;
}
.login-background {
  justify-content: center;
  min-height: 30vh;
  padding: 50px;
}

::-webkit-scrollbar {
  /* display: none; */
  width: 4px;
  height: 6px;
  border-radius: 8px;
  margin: "5px 0px";
}

::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f2f2f2;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.bca .MuiBox-root .css-8atqhb {
  display: flex;
  justify-content: center;
}

#dashboardTable th {
  padding: "4px 0";
  top: 0;
  position: sticky;
  background: #1d1030;
}
